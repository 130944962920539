<template>
  <tr>
    <td class="width">{{ item.name }}</td>
    <td class="width">{{ item.total_quantity }}</td>
    <td>
      {{ totalAmount }}
      <p class="text-danger mb-0" v-if="item.errorMessage">
        {{item.errorMessage}}
      </p>
    </td>
  </tr>
</template>

<script setup>
import {computed, inject} from "vue";

const props = defineProps(['item'])
const formData = inject("formData")

const totalAmount = computed(() => {
  return (props.item.total_quantity * formData.value.production_quantity).toFixed(2);
})

</script>

<style scoped>
.width {
  width: 300px;
}
</style>